import React,{ useEffect, useState,Fragment } from 'react';
import translate from '../../Translations/index';
import StoreLib from '../Library/store'

const BreadCrumb = (props) => {
    const [breadCrumbCategories,setBreadCrumbCategories] = useState(props.data);


    useEffect(() => {
        //console.log(props.selectedCategoryID);
        let breadCrumbCategories = buildBreadCrumbCategories();
        setBreadCrumbCategories(breadCrumbCategories);

    },[props.selectedCategoryID])


    const getBreadCrumbCategories = (selectedItem,categoreis) => {
        const storeLib = new StoreLib();
        categoreis.unshift(selectedItem);
        if(selectedItem.parentID){
            let nextItem = storeLib.searchItemByID(props.categories,selectedItem.parentID);
            return getBreadCrumbCategories(nextItem,categoreis);
        }else{
            return categoreis;
        }
    }

    const buildBreadCrumbCategories = () => {
        const storeLib = new StoreLib();
        let selectedItem = storeLib.searchItemByID(props.categories,props.selectedCategoryID);
        if(selectedItem){
            return getBreadCrumbCategories(selectedItem,[]);
        }else{
            return [];
        }
    }


    return(
        <div className="brad-crumb-menu">
            <div className="title">{translate.t("SelectedCategory")}</div>
            {breadCrumbCategories && breadCrumbCategories.map((item,index) => {
                return (
                    <Fragment key={index}>
                        <div onClick={() => props.setSelectedCategory(item.categoryID,item.name)} className={"menu-item " + (index == breadCrumbCategories.length - 1 ? "last" : "")}>{item.name}</div>
                        {index <= breadCrumbCategories.length - 2 &&
                            <div className="separator">/</div>
                        }
                    </Fragment>
                )
            })}           
        </div>
    )

}

export default BreadCrumb;