

/**
 *  Třída pro Goole Tag Manager
 */
class Gtm{

    sendSearchEvent(searchValue){
        try {         
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Hledání',
                'searchLabel': searchValue,
                //'searchValue': ""
            });         
        } catch (error) {
            console.log(error);
        }

    }

    sendCategoryEvent(categoryTitle){
        try {         
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Kategorie',
                'categoryLabel': categoryTitle,
                //'searchValue': ""
            });         
        } catch (error) {
            console.log(error);
        }

    }

    sendInquiryEvent(productName,productUnit,productValue){
        try {         
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Poptat',
                'inquiryAction': productName,
                'inquiryLabel': productUnit,
                'inquiryValue': productValue,
                //'searchValue': ""
            });         
        } catch (error) {
            console.log(error);
        }

    }

    sendOrderEvent(userName,orderValue){
        try {         
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Objednávka',
                'orderLabel': userName,
                'orderValue': orderValue,
                //'searchValue': ""
            });         
        } catch (error) {
            console.log(error);
        }

    }



}

export default Gtm;