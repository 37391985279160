import React,{ Component,Fragment, useState } from 'react';
import translate from '../../Translations/index';
import { useApolloClient, useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from '../Queries/changePassword';
import Helper from '../../../Library/helper';
import loadingIcon from '../../../Public/Images/loading.svg';


const ChangePassword = (props) => {
	
	const helper 							= new Helper();
	const client 							= useApolloClient();

	const [oldPassword,setOldPassword] 		= useState("");
	const [newPassword,setNewPassword]		= useState("");
	const [newPassword2,setNewPassword2]	= useState("");   
  
	const [changePassword, { data,loading: loginLoading, error: loginError }] = useMutation(CHANGE_PASSWORD,{
		onCompleted(data){

			helper.addNotification("success",translate.t("PasswordSuccessfullyChanged"),translate.t("SuccessfullyChanged"));
			setOldPassword("");
			setNewPassword("");
			setNewPassword2("");			
		},						
		onError(err){
			console.log(err);
			
			helper.addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
		}
	});

	const changePwd = () => {
	    	  	  
		if(oldPassword !== '' && newPassword !== '' && newPassword2 !== ''){
			if(newPassword == newPassword2){
				changePassword({variables:{oldPassword,newPassword,lang:translate.locale}});			
			}else{
				helper.addNotification("danger",translate.t("NewPasswordsNotEqual"),translate.t("Warning"));
			}		
		}else{
			helper.addNotification("danger",translate.t("NotFilledRequiredFields"),translate.t("Warning"));
		}
	}
		  
    return (
    	<div className="login content">
			<div className="d-flex justify-content-center">
				<div className="col-6 col-sm-4">
					<div className="card login-card">
						<div className="text-center fs30 font-weight-bold mt-3">
							{translate.t("ChangePassword")}
						</div>
						<div className="card-body">
							<form >
								<div className="form-group">
									
									<div className="input-group">
									<input
										onChange={event => setOldPassword(event.target.value)}
										value={oldPassword}
										type="password" className="form-control" placeholder={translate.t("OldPassword")} />
									</div>
			
								</div>
								<div className="form-group mb-4">
															
									<div className="input-group">
										<input
											onChange={event => setNewPassword(event.target.value)}
											value={newPassword}
											type="password" placeholder={translate.t("NewPassword")} className="form-control" aria-label="Password" />
									</div>
									
								</div>
								<div className="form-group mb-4">
															
									<div className="input-group">
										<input
											onChange={event => setNewPassword2(event.target.value)}
											value={newPassword2}
											type="password" placeholder={translate.t("NewPassword2")} className="form-control" aria-label="Password" />
									</div>
									
								</div>		        		
								<div className="row">
									<div className="col-6 pr-3 d-flex align-items-center">
										<button disabled={loginLoading} className={"send-inquiry btn btn-grey w-100 " + (loginLoading ? "loading" : "")} onClick={() => props.history.push("/" + translate.locale + "/store")}>
											{translate.t("Back")} 
										</button>
									</div>
									<div className="col-6 pl-3 d-flex justify-content-end">
										<button type="button" disabled={loginLoading} className={"send-inquiry btn w-100 " + (loginLoading ? "loading" : "")} onClick={() => changePwd()}>
											{loginLoading ?
												<img src={loadingIcon} className="send-loading" />
												:
												<>{translate.t("Send")}</>                                                    
											}
										</button>
									</div>
								</div>							
							</form>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
}

export default ChangePassword;
