import { transform } from "lodash";
import translate from '../../Translations/index';
import { store } from 'react-notifications-component';
import Helper from '../../../Library/helper';
import moment from "moment";
import {round} from 'mathjs';


class Store {

    constructor(props){
        this.props = props;
    }

    sendOrderToServer = (sendOrder,orderData,currency) => {
        const helper = new Helper();
        let warningMessage = null;

        if(orderData.products.length > 0){
            if(orderData.name != ""){
                if(orderData.phone != ""){
                    if(orderData.email != ""){
                        if(helper.validateEmail(orderData.email)){
                            if(orderData.deliveryTerm){
                                if(orderData.formConfirmation){

                                    let productsToSend = [];
                                    for(let product of orderData.products){
                                        productsToSend.push({
                                            productID:product.productID,
                                            categoryID:product.categoryID,
                                            code:product.code,
                                            name:product.name,
                                            amount:parseInt(product.amount),
                                            unitID:product.unitID
                                        })                                        
                                    }
                                    
                                    sendOrder({variables:{
                                        orderData: {
                                            customerID:orderData.customerID,
                                            currency,
                                            name:orderData.name,
                                            phone:orderData.phone,
                                            email:orderData.email,
                                            company:orderData.company,
                                            ic:orderData.ic,
                                            dic:orderData.dic,
                                            deliveryTerm:moment(orderData.deliveryTerm).format("YYYY-MM-DD HH:mm:ss"),
                                            additionalInfo:orderData.additionalInfo,
                                            lang:translate.locale,
                                            products:productsToSend
                                        }
                                    }});
                                    
                                }else{
                                    warningMessage = translate.t("DidNotInsertTermAndConditions");
                                }
                            }else{
                                warningMessage = translate.t("DidNotInsertTerm");
                            }
                        }else{
                            warningMessage = translate.t("IncorectEmailFormat");
                        }
                    }else{
                        warningMessage = translate.t("DidNotInsertEmail");
                    }
                }else{
                    warningMessage = translate.t("DidNotInsertPhone");
                }
            }else{
                warningMessage = translate.t("DidNotInsertName");
            }
        }else{
            warningMessage = translate.t("DidNotSelectAnyProduct");
        }
       
       if(warningMessage){
             
            store.addNotification({
                title: translate.t("Warning"),
                message: warningMessage,
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                //onScreen true
                }
            });
       }
    }

    buildCategoryTree = (categoreis) => {
        let categoryTree = [];

        for(let category of categoreis){
            if(!category.parentID){
                categoryTree.push({
                    categoryID:category.categoryID,
                    name:category.name,
                    parentID:0,
                    isOpen:false,
                    selected:false,
                    children:[]
                })
            }
        }

        this.completeSubCategories(categoryTree,categoreis);
        
        return categoryTree;


    }

    completeSubCategories = (levelCategories,allCategories) => {
        
        for(let category of allCategories){
            
            for(let levelCategory of levelCategories){
                if(category.parentID && category.parentID == levelCategory.categoryID){
                    levelCategory.children.push({
                        categoryID:category.categoryID,
                        name:category.name,
                        parentID:category.parentID,
                        isOpen:false,
                        children:[]
                    });
                }
            }
        }

        for(let levelCategory of levelCategories){
             this.completeSubCategories(levelCategory.children,allCategories);            
        }

    }

    getAllCategoryIDsForFilter = (categoryID,allCategories,categoryIDs) => {

        let category = this.searchItemByID(allCategories,categoryID);

        function recurseF(category,categoryIDs){

            if(category){
                categoryIDs.push(parseInt(category.categoryID));
                if(category && category.children.length > 0){
                    for(let item of category.children){
                        recurseF(item,categoryIDs);
                    }
                }
            }
        }
        recurseF(category,categoryIDs);

    }
    searchItemByID = (categories,categoryID) => {
           
        for(let it of categories){
            if(it.categoryID == categoryID){
                return it;
            }
                    
            if(it.children.length > 0){
                let item =  this.searchItemByID(it.children,categoryID);
                if(item){
                    return item;
                }
            }         
        }
        

    }
    syncItemsToOrder = (product,orderData,setOrderData) => {
        
        let products = [...orderData.products];
        let isProductIn = false;
        for(let [index,pr] of products.entries()){
            if(pr.productID == product.productID){
                products[index] = {...product};
                isProductIn = true;
                break;
            }
        }
        if(!isProductIn){
            products.push({...product});
        }
        setOrderData({...orderData,products:[...products]});
    }

    syncItemsToProducts = (e,product,allProducts,setAllProducts,orderData,setOrderData) => {        
        
        const t = e.target;
        const v = t.value;
        const n = t.name;

        let orderProducts = [...orderData.products];
        for(let pr of orderProducts){
            if(pr.productID == product.productID){
                console.log(n);
                console.log(v);
                pr[n] = v;
            }
        }
        setOrderData({...orderData,products:[...orderProducts]});

        
        let products = [...allProducts];
        let isProductIn = false;
        for(let [index,pr] of products.entries()){
            if(pr.productID == product.productID){
                products[index] = {...product};
                isProductIn = true;
                break;
            }
        }
        if(isProductIn){
            setAllProducts([...products]);
        }
        
    }

    
    setAdditionProductData = (allProducts,orderProducts) => {
        let products = [];
        for(let product of allProducts){

            let orderProduct = null;
            for(const orderPr of orderProducts){
                if(product.productID == orderPr.productID){
                    orderProduct = orderPr;
                }
            }

            products.push({
                ...product,
                amount:             orderProduct ? orderProduct.amount : 0,
                unitID:             orderProduct ? orderProduct.unitID : 1,
                originalPriceKG:    product.priceKG,
                originalPriceM:     product.priceM,
                formOpen:           false
            })
        }


        return products;
    }
    applyExchangeRateToProducs = (allProducts,exchangeRate) => {
        let products = [];
        for(let product of allProducts){
            let newProduct = {...product};
            if(newProduct.priceKG){
                newProduct.priceKG = round(newProduct.originalPriceKG / exchangeRate,2);
            }
            if(newProduct.priceM){
                newProduct.priceM = round(newProduct.originalPriceM / exchangeRate,2);
            }
            products.push(newProduct);
        }
        return products;
    }
    
    openLink = (e,url,inNewTab = false) => {
        e.preventDefault();
        e.stopPropagation();

        if(inNewTab){
            window.open(url, '_blank');
        }else{
            window.location = url;
        }

    }
    
    getHandlingFee = (orderData,settings,currency,euroExchangeRate) => {
        const settingsHandlingFee 	            = currency == "EUR" ? settings.handlingFeeEur : settings.handlingFee;
        const settingsPriceToApplyHandlingFee 	= currency == "EUR" ? settings.priceToApplyHandlingFeeEur : settings.priceToApplyHandlingFee;
        
        let productsPrice = 0;
        for(const product of orderData.products){
            productsPrice += round(product.amount * (product.unitID == 1 ? product.priceKG : product.priceM),2);
        }
        
        if(round(parseFloat(settingsPriceToApplyHandlingFee),2) > round(parseFloat(productsPrice),2)){
            return settingsHandlingFee;
        }else{
            return 0;
        }
    }
    getDeliveryPrice = (orderData,settings,currency,euroExchangeRate) => {
        const settingsDeliveryPrice 	= currency == "EUR" ? round(settings.deliveryPrice / euroExchangeRate,2) : settings.deliveryPrice;
        const settingsMinDeliveryPrice 	= currency == "EUR" ? round(settings.minDeliveryPrice / euroExchangeRate,2) : settings.minDeliveryPrice;

        let deliveryPrice = 0;
        for(const product of orderData.products){
            if(product.unitID == 1){
                deliveryPrice += round(product.amount * parseFloat(settingsDeliveryPrice),2);
            }else{
                const meterToKgAmount = parseFloat(product.priceM) / parseFloat(product.priceKG); // kolik Kg má jeden metr;
                deliveryPrice += round(product.amount * meterToKgAmount * settingsDeliveryPrice,2); // vypocet dopravy za kg
            }
        }

        if(deliveryPrice < settingsMinDeliveryPrice){
            deliveryPrice = settingsMinDeliveryPrice;
        }
        return round(deliveryPrice,2);
    }
    getOrderTotalPrice = (orderData,settings,currency,euroExchangeRate) => {
        let totalPrice = 0;
        for(const product of orderData.products){
            totalPrice += round(product.amount * (product.unitID == 1 ? product.priceKG : product.priceM),2);
        }
        const deliveryPrice = this.getDeliveryPrice(orderData,settings,currency,euroExchangeRate);
        const handlingFee   = this.getHandlingFee(orderData,settings,currency,euroExchangeRate);
        
        return  round(parseFloat(totalPrice) + parseFloat(deliveryPrice) + parseFloat(handlingFee),2);
    }

    removeProductFromOrders = (product,allProducts,setAllProducts,orderData,setOrderData) => {
        
        let products = [...orderData.products];
        for(let [index,pr] of products.entries()){
            if(pr.productID == product.productID){
                products.splice(index,1);
            }
        }
        setOrderData({...orderData,products:[...products]});

        for(let [index,pr] of allProducts.entries()){
            if(pr.productID == product.productID){
                allProducts[index] = {...product,amount:0,unitID:1,isOpen:false};
            }
        }
        setAllProducts([...allProducts]);
    }
    
    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     * @param {Number} productID ID produktu
     * @param {Array} allProducts pole vsech produktů
     * @param {Function} setAllProducts funkce pro nastaveni vsech produktů
     */
    
    productFormHandle(e,productID,allProducts,setAllProducts){
	  
        const t = e.target;
        const v = t.value;
        const n = t.name;
        
        let products = [...allProducts];
        for(let product of products){
            if(product.productID == productID){
                console.log(n);
                console.log(v);
                product[n] = v;
            }
        }

        setAllProducts(products);
              
    }
    /**
     * funkce nastavuje stav formulare daneho produktu
     * @param {Number} productID ID produktu
     * @param {Boolean} formOpen stav formuláře
     * @param {Array} allProducts pole vsech produktů
     * @param {Function} setAllProducts funkce pro nastaveni vsech produktů
     */
    
    setItemFormOpen(productID,formOpen,allProducts,setAllProducts){
	  
        
        let products = [...allProducts];
        for(let product of products){
            if(product.productID == productID){
                product.formOpen = formOpen;
            }
        }
        setAllProducts(products);
              
    }

    
    
}

export default Store;