/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import emailIcon from '../../../Public/Images/email.svg';
import key from '../../../Public/Images/key.svg';
import {LOGIN_USER} from '../Queries/login';
import {USER_CONTEXT} from '../../../GlobalQueries/globalQueries';
//import {Mutation,withApollo} from 'react-apollo';
import { useMutation,useQuery } from '@apollo/react-hooks';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import { useApolloClient } from '@apollo/client';
import loadingIcon from '../../../Public/Images/loading.svg';

const Login = (props) => {

	const client = useApolloClient();
	
	const helper = new Helper();

	const userData = client.readQuery({ query:USER_CONTEXT });
	
	const [email,setEmail] 			= useState("");
	const [password,setPassword]	= useState("");

	useEffect(() => {
		if(userData && userData.userContext){	
			helper.goToInitialPage(props.history,translate.locale);
		}
	},[]);

	const [login, { data,loading: loginLoading, error: loginError }] = useMutation(LOGIN_USER,{
		onCompleted(data){
			if(!data.loginCustomer.customer){		
				helper.addNotification("danger",translate.t("WrongCredentials"),translate.t("Warning"));
			}else{
				localStorage.setItem('token', data.loginCustomer.loginToken);
				
				client.writeQuery({
					query:USER_CONTEXT,
					data: {
						userContext: {
							name:data.loginCustomer.customer.name,
							email:data.loginCustomer.customer.email,
							customerID:data.loginCustomer.customer.customerID,           
							phone:data.loginCustomer.customer.phone,            
							company:data.loginCustomer.customer.company,			
							ic:data.loginCustomer.customer.ic,
							dic:data.loginCustomer.customer.dic,
							zip:data.loginCustomer.customer.zip,  
							allegroID:data.loginCustomer.customer.allegroID,
							priceListID:data.loginCustomer.customer.priceListID,
							__typename:"User"
						},
					},
				});
				
				helper.goToInitialPage(props.history,translate.locale);
			}
		},						
		onError(err){
			console.log(err);
			
			helper.addNotification("danger",err.toString(),translate.t("Error"));
		}
	});

	
  	const checkLogin = () => {
	    	  	  
		if(password !== '' && email !== ''){
			login({variables:{email,password}});			
		}else{
			helper.addNotification("danger",translate.t("NotFilledEmailOrPwd"),translate.t("Warning"));
		}

	}

	const handleKeyPress = (event) => {
		if(event.key === 'Enter'){
			checkLogin();
		}
	}

    return (
        <div className="login content">
			<div className="d-flex justify-content-center">
				<div className="col-6 col-sm-4">
					<div className="card login-card">
						<div className="text-center fs30 font-weight-bold mt-3">
							{translate.t("LogIn")}
						</div>
						<div className="card-body">
							<form >
								<div className="form-group">
									
									<div className="input-group">
									<input
										onKeyPress={handleKeyPress}
										onChange={event => setEmail(event.target.value)}
										value={email}
										type="text" className="form-control" placeholder={translate.t("Email")} aria-label="Username" />
									</div>
			
								</div>
								<div className="form-group mb-4">
															
									<div className="input-group">
										<input
											onKeyPress={handleKeyPress}
											onChange={event => setPassword(event.target.value)}
											value={password}
											type="password" placeholder={translate.t("Password")} className="form-control" aria-label="Password" />
									</div>
									
								</div>		        		
								<div className="row">
									<div className="col-6 pr-3 d-flex align-items-center">
										<button disabled={loginLoading} className={"send-inquiry btn btn-grey w-100 " + (loginLoading ? "loading" : "")} onClick={() => props.history.push("/" + translate.locale + "/store")}>
											{translate.t("Back")} 
										</button>
									</div>
									<div className="col-6 pl-3 d-flex justify-content-end">
										<button type="button" disabled={loginLoading} className={"send-inquiry btn w-100 " + (loginLoading ? "loading" : "")} onClick={() => checkLogin()}>
											{loginLoading ?
												<img src={loadingIcon} className="send-loading" />
												:
												<>{translate.t("LogIn")}</>                                                    
											}
										</button>
									</div>
								</div>
								<div className="text-center mt-4">
									<Link className="fs15" to={'/' + translate.locale + '/reset-password'}>{translate.t("ForgotYourPwd")}</Link>
								</div>								
							</form>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
}

export default Login;
