/**
 * soubor se seznamem veškerých graphql dotazů pro login
 */
import gql from 'graphql-tag';



export const LOGIN_USER = gql`
  mutation loginCustomer($email: String!,$password: String!) {
    loginCustomer(email: $email,password: $password){
        loginToken
        customer{
            name
            email
            customerID            
            phone            
            company			
            ic
            dic
            zip   
            allegroID
            priceListID
        }
    }
  } 
`;



