/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useState,useEffect } from 'react';
import {USER_CONTEXT} from '../../../GlobalQueries/globalQueries';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import { useApolloClient } from '@apollo/client';
import Loading from '../../../GlobalComponents/Loading';

const Logout = (props) => {

	const client = useApolloClient();
	
	const helper = new Helper();

	useEffect(() => {
		
		localStorage.removeItem('token');

		client.writeQuery({
			query:USER_CONTEXT,
			data: {
				userContext: null
			},
		});
		helper.goToInitialPage(props.history,translate.locale);
		
	},[]);


    return (
        <div className="content">
			<Loading />
        </div>
    );
}

export default Logout;
