


import { store } from 'react-notifications-component';
/**
 *  Pomocná třída s obecnými funkcemi
 */
class Helper{

    getApolloErrorText(error){

        error = JSON.stringify(error);
        error = JSON.parse(error);
        try {
            
            if(error.graphQLErrors[0])return error.graphQLErrors[0].message;
            if(error.networkError && error.networkError.result.errors){
                return error.networkError.result.errors[0].message;
            }
        } catch (e) {
          error.toString();  
        }

    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    goToInitialPage(history,lang){
        history.push("/" + lang + "/store");
    }


    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

     addNotification(type,text,title = null,duration = 7000) {

        store.addNotification({
            title: title,
            message: text,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
            duration,
            //onScreen true
            }
        });
      }

}

export default Helper;