/**
 * soubor se seznamem veškerých graphql dotazů pro články
 */
import gql from 'graphql-tag';

/**
 * vyber vsech kategorii
 */
export const GET_CATEGORIES = gql`
    query GetCategoreies{
        allCategories{
            categoryID
            parentID
            code
            name
            fullName
        }
    }
`;
/**
 * vyber vsech produktu
 */
export const GET_PRODUCTS = gql`
    query GetProducts($filterParams:ProductsFilterParams,$customerID:ID){
        allProducts(filterParams:$filterParams,customerID:$customerID){
            productID
            categoryID
            code
            name  
            priceKG
            priceM          
        }
        productCount(filterParams:$filterParams,customerID:$customerID)
        euroExchangeRate
        settings{
            deliveryPrice
            minDeliveryPrice
            handlingFee
            priceToApplyHandlingFee
            handlingFeeEur
            priceToApplyHandlingFeeEur
            allowOrderTypeID
        }
    }
`;

export const SEND_ORDER = gql`
    mutation SendOrder($orderData:OrderDataInput){
        sendOrder(orderData:$orderData){
            orderNumber
        }
    }
`;
