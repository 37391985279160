import React,{ useEffect, useState } from 'react';
import plus from '../../../Public/Images/plus.svg';
import minus from '../../../Public/Images/minus.svg';

const Categories = (props) => {
    
    const [categories,setCategories] = useState(props.data);

    useEffect(() => {
            setCategories(props.data);
    },[props.data])
    useEffect(() => {
        function checkSelected(categoryItems){
            
            for(let it of categoryItems){
                if(it.categoryID == props.selectedCategoryID){
                    it.selected = true;
                }else{
                    it.selected = false;
                }
                     
                if(it.children.length > 0){
                    checkSelected(it.children);
                }         
            }
        }
        if(categories.length > 0){
            let newCategories = [...categories];
            checkSelected(newCategories);
            setCategories(newCategories);
        }
    },[props.selectedCategoryID])

    const openCloseCategory = (e,item) => {
        e.stopPropagation();
        e.preventDefault();
         function checkItem(categoryItems){
            
            for(let it of categoryItems){
                if(it.categoryID == item.categoryID){
                    it.isOpen = !item.isOpen;
                    return;
                }       
                if(it.children.length > 0){
                    checkItem(it.children);
                }         
            }
        }
        let newCategories = [...categories];
        checkItem(newCategories);
        setCategories(newCategories);
    }

    const selectCategory = (item) => {   
        props.setSelectedCategory(item.categoryID,item.name);
    }

    const CategoryItem = (props) => {

        const style = {
            paddingLeft: 1.5 * props.level + "rem"
        };
        return (
            <li className={(props.item.isOpen ? "open" : "closed")}>
                <div style={style} className={"item-row " + (props.item.selected ? "selected" : "")} onClick={() => {selectCategory(props.item)}}>
                    {props.item.children.length > 0 &&
                        <>
                            {props.item.isOpen ? 
                                <div className="icon open" onClick={(e) => {openCloseCategory(e,props.item)}}><img src={minus}/></div>
                                :
                                <div className="icon closed" onClick={(e) => {openCloseCategory(e,props.item)}}><img src={plus}/></div>
                            }
                        </>
                    }
                    <div>{props.item.name}</div>
                </div>
                {props.item.children.length > 0 && 
                    <ul>                        
                        {props.item.children.map((item,index) => {
                            return (
                                <CategoryItem key={index} item={item} level={props.level + 1}/>
                            )
                        })}
                    </ul>
                }
            </li>
        )
    
    };
    return(
        <div className="category-list">
            <ul>
                {categories && categories.map((item,index) => {
                    return (
                        <CategoryItem key={index} item={item} level={1} />
                    )
                })}
            </ul>
        </div>
    )

}

export default Categories;