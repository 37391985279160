import React, {Component,Fragment, useEffect, useState} from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { ApolloProvider} from "@apollo/react-hooks";
import { createUploadLink } from 'apollo-upload-client';
import {GRAPHQL_SERVER_URL} from './Config/index';
import {ApolloLink,concat} from 'apollo-link';

// routování url adres dsf dsfg dfg dg df gdfg dfg df gdfg dfg rfasdf asdf asdf asd fassad
import Routes from './Routes/index.js'; 

//resolvery a schema pro lokalní uložení
import {resolvers, typeDefs} from './Resolvers/resolvers';
import WithTranslation from './Modules/Translations/withTranslation';
import { GET_NOTIFICATIONS, USER_CONTEXT } from './GlobalQueries/globalQueries';
import TagManager from 'react-gtm-module'
import Loading from './GlobalComponents/Loading';

const tagManagerArgs = {
    gtmId: 'GTM-K3R8CDT'
}

TagManager.initialize(tagManagerArgs)

// inicializace Cache - dá se do této cache ukládat hodnoty pro příští použití
const cache  = new InMemoryCache();

// URl adresa na server pro graphql
const httpLink = new createUploadLink({ 
	uri: GRAPHQL_SERVER_URL,
	 
});

//prostředník, který při každém requestu zasílá token na server z localstorage
const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the headers
	operation.setContext({
	  headers: {
		authorization: localStorage.getItem('token') || null,
	  }
	});
  
	return forward(operation);
});

//Apollo klient, který se přihlašuje na server
const client = new ApolloClient({
  cache,
  link: concat(authMiddleware, httpLink), 
  resolvers,
  typeDefs,
});
client.writeQuery({query:GET_NOTIFICATIONS,data:{notifications:[]}});

const App = () => {
  
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    getUserData();
  },[]);

	const getUserData = async () => {

		const data = await client.query({ 
			query: USER_CONTEXT,
			errorPolicy:"all",
			fetchPolicy: 'network-only'
		});
    if(!data || !data.data || !data.data.userContext){      
				localStorage.removeItem('token');
    }
		setLoading(false);
	}


  return (
    <WithTranslation>
      {!loading ? 
          <ApolloProvider client={client}>
            <Routes />	   
          </ApolloProvider>
          :
          <Loading />
      }
    </WithTranslation>
  );
    
}

export default  App;
