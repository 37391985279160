/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import emailIcon from '../../../Public/Images/email.svg';
import key from '../../../Public/Images/key.svg';
import {RESET_PASSWORD} from '../Queries/changePassword';
import {USER_CONTEXT} from '../../../GlobalQueries/globalQueries';
//import {Mutation,withApollo} from 'react-apollo';
import { useMutation,useQuery } from '@apollo/react-hooks';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import { useApolloClient } from '@apollo/client';
import loadingIcon from '../../../Public/Images/loading.svg';

const ResetPassword = (props) => {

	const client = useApolloClient();
	
	const helper = new Helper();

	const userData = client.readQuery({ query:USER_CONTEXT });
	
	const [email,setEmail] 			= useState("");

	useEffect(() => {
		if(userData && userData.userContext){	
			helper.goToInitialPage(props.history,translate.locale);
		}
	},[]);

	const [resetPassword, { data,loading: loginLoading, error: loginError }] = useMutation(RESET_PASSWORD,{
		onCompleted(data){	
			setEmail("");
			helper.addNotification("success",translate.t("WeHaveSendYouResetLink"),translate.t("LinkSent"));
		},						
		onError(err){
			console.log(err);			
			helper.addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
		}
	});

	
  	const checkForm = () => {
	    	  	  
		if(email !== ''){
			resetPassword({variables:{email,lang:translate.locale}});			
		}else{
			helper.addNotification("danger",translate.t("NotFilledEmail"),translate.t("Warning"));
		}

	}

	const handleKeyPress = (event) => {
		if(event.key === 'Enter'){
			checkForm();
		}
	}

    return (
        <div className="login content">
			<div className="d-flex justify-content-center">
				<div className="col-6 col-sm-4">
					<div className="card login-card">
						<div className="text-center fs30 font-weight-bold mt-3">
							{translate.t("ResetPassword")}
						</div>
						<div className="text-center fs12 font-weight-bold mt-3">
							{translate.t("ResetPasswordInfoText")}
						</div>
						<div className="card-body">
							<form >
								<div className="form-group">
									
									<div className="input-group">
									<input
										onKeyPress={handleKeyPress}
										onChange={event => setEmail(event.target.value)}
										value={email}
										type="text" className="form-control" placeholder={translate.t("Email")} aria-label="Username" />
									</div>
			
								</div>	        		
								<div className="row">
									<div className="col-6 pr-3 d-flex align-items-center">
										<button disabled={loginLoading} className={"send-inquiry btn btn-grey w-100 " + (loginLoading ? "loading" : "")} onClick={() => props.history.push("/" + translate.locale + "/store")}>
											{translate.t("Back")} 
										</button>
									</div>
									<div className="col-6 pl-3 d-flex justify-content-end">
										<button type="button" disabled={loginLoading} className={"send-inquiry btn w-100 " + (loginLoading ? "loading" : "")} onClick={() => checkForm()}>
											{loginLoading ?
												<img src={loadingIcon} className="send-loading" />
												:
												<>{translate.t("Send")}</>                                                    
											}
										</button>
									</div>
								</div>							
							</form>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
}

export default ResetPassword;
