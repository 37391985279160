/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
  mutation changeCustomerPassword($oldPassword: String!,$newPassword: String!,$lang:String) {
    changeCustomerPassword(oldPassword: $oldPassword,newPassword: $newPassword, lang:$lang)
  } 
`;
export const RESET_PASSWORD = gql`
  mutation resetCustomerPassword($email: String!,$lang:String) {
    resetCustomerPassword(email: $email, lang:$lang)
  } 
`;