let graphqlServerUrl              = "https://skladserver.favex.cz/api/graphql";
let serverUrl                     = "https://skladserver.favex.cz";


if (process && process.env && process.env.NODE_ENV === "development") {
    graphqlServerUrl              = "http://localhost:8914/api/graphql";
    serverUrl                      = "http://localhost:8914";
}

export const GRAPHQL_SERVER_URL              = graphqlServerUrl;
export const SERVER_URL                      = serverUrl;
//export const BANNER_IMAGE_URL                = "Public/Images/Banner";
