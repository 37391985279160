
import translate from '../Modules/Translations/index';
import React,{ Component } from 'react';
import { USER_CONTEXT } from '../GlobalQueries/globalQueries';
import { useApolloClient } from '@apollo/client';
import { Link } from "react-router-dom";

const AccountBlock = (props) => {
    
	const client    = useApolloClient();
	const userData  = client.readQuery({ query:USER_CONTEXT });

    return (    
      <div className="account-block">
        {!!userData.userContext ?
          <div className="d-flex flex-row align-items-center">
            <Link className="fs15 font-weight-bold" to={"/" + translate.locale + "/account"}>{userData.userContext.name}</Link>
            <span className="separator"></span>
					  <Link className="fs15 font-weight-bold" to={"/" + translate.locale + "/logout"}>{translate.t("LogOut")}</Link>
          </div>
          :
          <>
					  <Link className="fs15 font-weight-bold" to={"/" + translate.locale + "/login"}>{translate.t("LogIn")}</Link>
            <span className="separator"></span>
					  <Link className="fs15 font-weight-bold" to={"/" + translate.locale + "/registration"}>{translate.t("Register")}</Link>
          </>
        }
      </div>
    );
  
}


export default AccountBlock;
