/*
    routování aplikace
*/
import React, { Component } from 'react';
import {Switch, Route } from 'react-router-dom';
import Layout from '../Layout/Layout.js';
import NoMatch from './NoMatch';


class Routes extends Component {
	
  render() {
	  	  
    return (
        <Switch>
        
          <Route exact path='/' component={NoMatch}></Route>
          <Route path='/:l' component={Layout}></Route>
          
	        <Route component={NoMatch} />

	      </Switch>
    );
  }
  
}
export default Routes;