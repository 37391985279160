import React, { Component, useEffect, useState } from 'react';
import translate from './index';
import Loading from '../../GlobalComponents/Loading';
import {GET_ADMIN_LANGUAGES} from '../../GlobalQueries/globalQueries';

const WithTranslation = (props) => {
	const {appComponent} = props;
	const [loading,setLoading] = useState(true);
			
    useEffect(() => {
        setLang();
    }, []);

	const setLang = async () => {
		try {
			
			let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : "";

			if(lang == ""){
				lang = 'cz';
				localStorage.setItem('lang',lang);
			}
			translate.locale = lang;

			setLoading(false);
			
		} catch(error) {
			// error reading value
			//this.setState({loading:false});
			alert('Error: ' + error);
		}
	}
	
	if(loading){
		return (
			<Loading />
		);
	}else{
		return (	
			props.children
		);
	}


  
}

export default WithTranslation;