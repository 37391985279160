import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    notifications: [Notification]
  }


`;


export const resolvers = {};