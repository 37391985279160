import React,{ Component, Fragment, useEffect, useRef, useState } from 'react';
import SearchInput from './SearchInput';
import translate from '../../Translations/index';
import Categories from './Categories';
import BreadCrumb from './BreadCrumb';
import Helper from '../../../Library/helper';
import Pagination from "react-js-pagination";
import remove from '../../../Public/Images/remove.svg';
import confirm from '../../../Public/Images/confirm.svg';
import menu from '../../../Public/Images/menu.svg';
import info from '../../../Public/Images/info.svg';
import { GET_CATEGORIES, GET_PRODUCTS,SEND_ORDER } from '../Queries/store';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import StoreLib from '../Library/store'
import Gtm from '../../../Library/gtm';
import Loading from '../../../GlobalComponents/Loading'
import { store } from 'react-notifications-component';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import loadingIcon from '../../../Public/Images/loading.svg';
import AccountBlock from '../../../GlobalComponents/AccountBlock';
import { USER_CONTEXT } from '../../../GlobalQueries/globalQueries';
import {round} from 'mathjs';

registerLocale('cs', cs);


const Store = (props) => {

    translate.locale = props.match.params.l;

    const storeLib = new StoreLib();
    const gtmLib   = new Gtm();    

	const client    = useApolloClient();
	const userData  = client.readQuery({ query:USER_CONTEXT });
    
    const heightRef         = useRef(0);
    const contentRef        = useRef();
    
	const [currency,setCurrency] = useState("CZK");
	const [euroExchangeRate,setEuroExchangeRate] = useState(0);
	const [orderNumber,setOrderNumber] = useState(0);
	const [showThankYou,setShowThankYou] = useState(false);
	const [scrollTopOffset,setScrollTopOffset] = useState(0);
    const [showMenu,setShowMenu] = useState(false);
    const [productsLoading,setProductsLoading] = useState(true);
    const [showInfo,setShowInfo] = useState(false);
    const [allCategories,setAllCategories] = useState([]);
    const [allProducts,setAllProducts] = useState([]);
    const [productCount,setProductCount] = useState(0);
    const [selectedPage,setSelectedPage] = useState(1);
    const [searchText,setSearchText] = useState("");
    const [selectedCategoryID,setSelectedCategoryID] = useState(0);
    const [orderData,setOrderData] = useState({
        customerID:!!userData && !!userData.userContext ? userData.userContext.customerID : 0,
        name: !!userData && !!userData.userContext ? userData.userContext.name : "",
        phone: !!userData && !!userData.userContext ? userData.userContext.phone : "",
        email: !!userData && !!userData.userContext ? userData.userContext.email : "",
        company: !!userData && !!userData.userContext ? userData.userContext.company : "",
        ic: !!userData && !!userData.userContext ? userData.userContext.ic : "",
        dic: !!userData && !!userData.userContext ? userData.userContext.dic : "",
        deliveryTerm:"",
        additionalInfo:"",
        formConfirmation:false,
        products:[]
    });
    const [filterParams,setFilterParams] = useState({
        onPage:20,
        offset:0,
        searchQuery:"",
        orderBy:"productID",
        orderDirection:"ASC",
        categoryIDs:[],
    });
	

    const {loading, error:getError, data:branchData, refetch:refetchCategories} = useQuery(GET_CATEGORIES,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted(data){

            let categoryTree = storeLib.buildCategoryTree(data.allCategories);
            for(let category of categoryTree){
                category.isOpen = true;
            }
            setAllCategories(categoryTree);

        }
    });

    const {loading:prLoading, error:productsError, data:productsData, refetch:refetchProducts} = useQuery(GET_PRODUCTS,{
        fetchPolicy:"network-only",
        notifyOnNetworkStatusChange: true,
        variables:{
            filterParams,
            customerID: orderData.customerID
        },
        onCompleted(data){
            if(productsLoading){
                setProductsLoading(false);
            }

            let products    = storeLib.setAdditionProductData(data.allProducts,orderData.products);
            if(currency == "EUR"){
                products        = storeLib.applyExchangeRateToProducs(products,currency == "EUR" ? data.euroExchangeRate : 1);
            }
            setAllProducts(products);
            setProductCount(data.productCount);
            setEuroExchangeRate(data.euroExchangeRate);
        }
    });
    
	const [sendOrder, { data:sendOrderData,loading:sendOrderLoading, error: sendOrderError }] = useMutation(SEND_ORDER,{	
		onCompleted(data){

            setOrderNumber(data.sendOrder.orderNumber);
            setShowThankYou(true);

            let totalAmountSum = 0;
            for(let product of allProducts){
                totalAmountSum += parseInt(product.amount);                
            }
            gtmLib.sendOrderEvent(orderData.name,totalAmountSum);

			setOrderData({
                ...orderData,
                name:"",
                phone:"",
                email:"",
                company:"",
                ic:"",
                dic:"",
                deliveryTerm:"",
                additionalInfo:"",
                formConfirmation:false,
                products:[]
            });

            let products = [...allProducts];
            for(let product of products){
                product.amount      = 0;
                product.unitID      = 1;
                product.formOpen    = false;
                
            }
            setAllProducts(products);

            store.addNotification({
                title: translate.t("OrderSent"),
                message: translate.t("OrderSuccessfullySent"),
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 7000,
                //onScreen true
                }
            });
        },
        onError(error){
            console.log(error);
            const helper = new Helper();
            store.addNotification({
                title: translate.t("Error"),
                message: helper.getApolloErrorText(error),
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 7000,
                //onScreen true
                }
            });
        }
    });

    const resizeListener = () => {
        var body   = document.body, html = document.documentElement;
        var pageH  = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        //console.log(pageH);
        window.parent.postMessage(["setHeight", pageH], "*");
    };

    const selectPage = (page) => {
        setSelectedPage(page);
        setFilterParams({...filterParams,offset:(page - 1) * filterParams.onPage});
    }

    const setSelectedCategory = (categoryID,name) => {
        setSelectedCategoryID(categoryID);
        let categoryIDs = [];
        storeLib.getAllCategoryIDsForFilter(categoryID,allCategories,categoryIDs);
        
        
        setSelectedPage(1);
        setFilterParams({...filterParams,categoryIDs,offset:0,orderBy:"productID",orderDirection:"ASC",searchQuery:""});
        setSearchText("");
        //console.log(name);
        gtmLib.sendCategoryEvent(name);
    } 

    const setOrder = (orderBy) => {
        if(orderBy == filterParams.orderBy){
            setFilterParams({...filterParams,offset:0,orderDirection: filterParams.orderDirection == "ASC" ?  "DESC" : "ASC"});
        }else{
            setFilterParams({...filterParams,offset:0,orderBy,orderDirection:"ASC"});
        }
        setSelectedPage(1);
    }

    const delaydTextFunction = (text) => {
        setFilterParams({...filterParams,searchQuery:text,offset:0,orderBy:"productID",orderDirection:"ASC",categoryIDs:[]});
        setSelectedCategoryID(0);
        setSelectedPage(1);
        gtmLib.sendSearchEvent(text);
    }

    const changeCurrency = (currency) => {
        
        let products        = storeLib.applyExchangeRateToProducs(allProducts,currency == "EUR" ? euroExchangeRate : 1);
        setAllProducts(products);
        
        products        = storeLib.applyExchangeRateToProducs(orderData.products,currency == "EUR" ? euroExchangeRate : 1);
        setOrderData({...orderData,products});
        setCurrency(currency);
    }

    const confirmProductForm = (product) => {
        if(product.amount > 0){
            storeLib.setItemFormOpen(product.productID,false,allProducts,setAllProducts);
            
            storeLib.syncItemsToOrder(product,orderData,setOrderData);

            gtmLib.sendInquiryEvent(product.name,product.unitID == 1 ? "kg" : "m",product.amount);
            
            store.addNotification({
                title: translate.t("Added"),
                message: translate.t("ProductSuccessfullyAdded"),
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                //onScreen true
                }
            });
        }else{
            store.addNotification({
                title: translate.t("Warning"),
                message: translate.t("YouDidNotSpecifyAmount"),
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                duration: 3000,
                //onScreen true
                }
            });
        }
    }
    useEffect(() => {
        if(contentRef && contentRef.current && contentRef.current.clientHeight != heightRef.current){
            heightRef.current = contentRef.current.clientHeight;
            resizeListener();
        }
    })

    useEffect(() => {
        function setScrollOffset(e){
            if(e.data[0] == 'setScrollOffset') { 
                //iframe.style.setProperty('height',e.data[1]+"px");
				const offset = e.data[1];
                //console.log(offset);
				if(offset <= 0){
					setScrollTopOffset(Math.abs(offset));
				}
            } 
            
        }
        window.addEventListener("message", setScrollOffset);

        return () => {
            // remove resize listener
            window.removeEventListener('message', setScrollOffset);
        }
	},[])
    
    useEffect(() => {
        function windowResized(e){
            resizeListener();            
        }
        window.addEventListener("resize", windowResized);

        return () => {
            // remove resize listener
            window.removeEventListener('resize', windowResized);
        }
	},[])

    const checkAllowBindingOrder = () => {
        if(!!orderData && !!productsData){
            if(orderData.customerID != 0){
                if(productsData.settings.allowOrderTypeID == 1){
                    return true;
                }else if(productsData.settings.allowOrderTypeID == 2 && userData.userContext.allegroID == "0"){
                    return true;
                }
            }
        }
        return false;
    }

    return(
        <div className="d-flex content align-items-stretch" ref={contentRef}>
            {!loading ? 
                <>
                    <div className={"search-column " + (showMenu ? "show" : "")}>
                        <SearchInput searchText={searchText} setSearchText={setSearchText} delaydTextFunction={delaydTextFunction}/>
                        <Categories data={allCategories} selectedCategoryID={selectedCategoryID} setSelectedCategory={setSelectedCategory}/>
                    </div>
                    <div className="d-flex flex-column flex-xl-row flex-grow-1">
                        <div className="content-column">
                            <div className="menu">
                                <div className="menu-icon"><img onClick={() => setShowMenu(!showMenu)} src={menu} /></div>
                                <BreadCrumb categories={allCategories} selectedCategoryID={selectedCategoryID}  setSelectedCategory={setSelectedCategory}/>   
                            </div>
                            {checkAllowBindingOrder() &&
                                <div className="d-flex flex-row mb-4 mt-2">
                                    <div className="fs18 font-weight-bold mr-3 py-3 ml-auto">{translate.t("ChooseCurrency")}:</div>
                                    <div className="currency-switcher d-flex flex-row align-items-center">
                                        <div className={"item fs20 mr-2 " + (currency == "CZK" ? "selected" : "")} onClick={() => changeCurrency("CZK")}>CZK</div>
                                        <div className={"item fs20 " + (currency == "EUR" ? "selected" : "")} onClick={() => changeCurrency("EUR")}>EUR</div>
                                    </div>
                                </div>
                            }
                            {!productsLoading ?
                                <>
                                    <div className="products">
                                        <div className="header">
                                            <div className="item code d-flex align-items-center">
                                                <div className="cont" onClick={() => setOrder("code")}>
                                                    {translate.t("Code")}
                                                    <div className="ml-3 d-flex flex-column justify-items-center">
                                                        <div className={"triangle-to-top mb-2 " + (filterParams.orderBy == "code" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                        <div className={"triangle-to-bottom mt-2 " + (filterParams.orderBy == "code" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {checkAllowBindingOrder() &&
                                                <div className="item price d-flex align-items-center justify-content-center">
                                                    <div className="cont" onClick={() => setOrder("priceKG")}>
                                                        {translate.t("Price")}
                                                        <div className="ml-3 d-flex flex-column justify-items-center">
                                                            <div className={"triangle-to-top mb-2 " + (filterParams.orderBy == "priceKG" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                            <div className={"triangle-to-bottom mt-2 " + (filterParams.orderBy == "priceKG" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="item name d-flex align-items-center">
                                                <div className="cont" onClick={() => setOrder("name")}>
                                                    {translate.t("Name")}
                                                    <div className="ml-3 d-flex flex-column justify-items-center">
                                                        <div className={"triangle-to-top mb-2 " + (filterParams.orderBy == "name" && filterParams.orderDirection == "ASC" ? "selected" : "")}></div>
                                                        <div className={"triangle-to-bottom mt-2 " + (filterParams.orderBy == "name" && filterParams.orderDirection == "DESC" ? "selected" : "")}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="body">
                                            {allProducts && allProducts.map((item,index) => {
                                                return (
                                                    <div key={index} className="one-row">
                                                        <div className="item code">{item.code}</div>
                                                        {checkAllowBindingOrder() &&
                                                            <div className="item price flex-column">
                                                                <div className="mb-1">
                                                                    {currency == "EUR" && "€"}
                                                                    {translate.toNumber(item.priceKG)} 
                                                                    {currency == "CZK" && " Kč"}
                                                                    {" / Kg"}
                                                                </div>
                                                                <div>                                                                  
                                                                    {currency == "EUR" && "€"}
                                                                    {translate.toNumber(item.priceM)} 
                                                                    {currency == "CZK" && " Kč"} 
                                                                    {" / m"}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="item name">
                                                            <div className="text">{item.name}</div>
                                                            <div className="form d-flex">    
                                                                {!item.formOpen ?                                            
                                                                    <button type="button" className={"form-control inquiry-button " + (item.amount > 0 ? "selected" : "")}  onClick={() => storeLib.setItemFormOpen(item.productID,true,allProducts,setAllProducts)}>{translate.t("Inquire")} {(item.amount > 0 ? "(" + item.amount + ")" : "")}</button>
                                                                    :
                                                                    <>
                                                                        <div className="amount-label">{translate.t("Amount")}</div>
                                                                        <input type="number" name="amount" value={item.amount} className="form-control amount-input" onChange={(e) => storeLib.productFormHandle(e,item.productID,allProducts,setAllProducts)} />
                                                                        <select name="unitID" value={item.unitID} className="form-control unit-select" onChange={(e) => storeLib.productFormHandle(e,item.productID,allProducts,setAllProducts)} >
                                                                            <option value="1">kg</option>
                                                                            <option value="2">m</option>
                                                                        </select>        
                                                                        <button type="button" className="form-control confirm-button" onClick={() => confirmProductForm(item)}>OK</button>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div> 
                                    <div className="paging">
                                        <div className="on-page-block">
                                            {translate.t("OnPageCount")}
                                            <select name="onPage" value={filterParams.onPage} className="form-controll on-page-select" onChange={(e) => setFilterParams({...filterParams,onPage:parseInt(e.target.value)})}>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                        <div className="d-flex flex-grow-1 align-items-center justify-content-center flex-row">
                                            <Pagination
                                                activePage={selectedPage}
                                                itemsCountPerPage={filterParams.onPage}
                                                totalItemsCount={productCount}
                                                pageRangeDisplayed={5}
                                                hideFirstLastPages={true}
                                                prevPageText="<"
                                                nextPageText=">"
                                                onChange={(number) => selectPage(number)}
                                                activeLinkClass="selected"
                                            />
                                        </div>
                                        <div className="results-shown-count">
                                            <div className="mr-2">{translate.t("Showing")}</div>
                                            <div className="mr-2"><strong>{filterParams.onPage <= productCount ? filterParams.onPage : productCount}</strong></div>
                                            <div className="mr-2">{translate.t("Of")}</div>
                                            <div className="mr-2">{productCount}</div>
                                            <div>{translate.t("Items")}</div>
                                        </div>
                                    </div>    
                                </>
                                : 
                                <div className="mb-5">
                                    <Loading />
                                </div>
                            }
                            <div className="order-block">
                                <div className="order-title">{checkAllowBindingOrder() ? translate.t("BuyProducts") : translate.t("OrderProducts")}</div>
                                {!showThankYou ?
                                    <>
                                        <div className="selected-products">
                                            <div className="header">
                                                <div className="item product">{translate.t("SelectedProducts")} <div className="required">*</div></div>
                                                <div className="item amount">{translate.t("Amount")} <div className="required">*</div></div>
                                                <div className="item unit">{translate.t("Unit")} <div className="required">*</div></div>
                                                {checkAllowBindingOrder() &&
                                                    <div className="item price">{translate.t("Price")}</div>
                                                }
                                                <div className="item remove"></div>
                                            </div>
                                            <div className="body">
                                                {!!orderData.products && orderData.products.map((item,index) => {
                                                    return (                                                    
                                                        <div className="one-row" key={index}>
                                                            <div className="item code">{item.code}</div>
                                                            <div className="item name">{item.name}</div>
                                                            <div className={"form-inputs " + (checkAllowBindingOrder() ? "inputs-with-price" : "")}>
                                                                <div className="item amount"><input name="amount" value={item.amount} onChange={(e) => storeLib.syncItemsToProducts(e,item,allProducts,setAllProducts,orderData,setOrderData)} type="number" placeholder={translate.t("Amount")} className="form-control amount-input" /></div>
                                                                <div className="item unit">
                                                                    <select name="unitID" value={item.unitID} onChange={(e) => storeLib.syncItemsToProducts(e,item,allProducts,setAllProducts,orderData,setOrderData)} className="form-control unit-select" >
                                                                        <option value="1">kg</option>
                                                                        <option value="2">m</option>
                                                                    </select> 
                                                                </div>
                                                                                                                          
                                                                {checkAllowBindingOrder() &&
                                                                    <div className="item price">                                
                                                                        {currency == "EUR" && "€"}
                                                                        {translate.toNumber(round(item.amount * (item.unitID == 1 ? item.priceKG : item.priceM),2))}
                                                                        {currency == "CZK" && " Kč"} 
                                                                    </div>                                                                
                                                                }                                                              
                                                                <div className="item remove"><img onClick={() => storeLib.removeProductFromOrders(item,allProducts,setAllProducts,orderData,setOrderData)} src={remove}/></div>
                                                            </div>                                                                                                                                

                                                        </div>
                                                    )
                                                })}
                                                
                                                {!!orderData.products && orderData.products.length == 0 &&
                                                    <div className="no-products-inserted">{translate.t("NoItemsInsertedYet")}</div>
                                                }  

                                                {checkAllowBindingOrder() && !!productsData && !!orderData.products && orderData.products.length > 0 &&
                                                    <>
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="handling-price-label flex-grow-1">
                                                                {translate.t("HandlingFee")}:                                                                
                                                                <div className="info">
                                                                    {translate.t("HandlingFeeInfo") + " "}                                                            
                                                                    {currency == "EUR" && "€"}
                                                                    {currency == "CZK" ? translate.toNumber(productsData.settings.priceToApplyHandlingFee) : translate.toNumber(productsData.settings.priceToApplyHandlingFeeEur)} 
                                                                    {currency == "CZK" && " Kč"} 
                                                                    {" " + translate.t("HandlingFeeInfoWithoutWat")}
                                                                </div>
                                                            </div>
                                                            <div className="handling-price-amount ml-auto">                                                                
                                                                {currency == "EUR" && "€"}
                                                                {translate.toNumber(storeLib.getHandlingFee(orderData,productsData.settings,currency,euroExchangeRate))} 
                                                                {currency == "CZK" && " Kč"} 
                                                            </div>  
                                                        </div> 
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="delivery-price-label">{translate.t("DeliveryPrice")}:</div>
                                                            <div className="delivery-price-amount ml-auto">                                                                
                                                                {currency == "EUR" && "€"}
                                                                {translate.toNumber(storeLib.getDeliveryPrice(orderData,productsData.settings,currency,euroExchangeRate))} 
                                                                {currency == "CZK" && " Kč"} 
                                                            </div>  
                                                        </div>          
                                                        <div className="d-flex flex-row align-items-top">
                                                            <div className="total-price-label flex-grow-1">
                                                                <div>{translate.t("TotalWithoutVat")}:</div>
                                                                <div className="info">{translate.t("PriceWithoutVatInfo")}</div>                                                                
                                                            </div>
                                                            <div className="total-price-amount ml-auto">                                                             
                                                                {currency == "EUR" && "€"}
                                                                {translate.toNumber(storeLib.getOrderTotalPrice(orderData,productsData.settings,currency,euroExchangeRate))} 
                                                                {currency == "CZK" && " Kč"} 
                                                            </div>  
                                                        </div>  
                                                    </>                                                            
                                                }   

                                            </div>
                                        </div>
                                        <div className="order-form">
                                            <div className="row">
                                                <div className="col-6 col-sm-4 form-group">
                                                    <label>{translate.t("NameAndSurname")}<div className="required">*</div></label>
                                                    <input type="name" className="form-control" value={orderData.name} onChange={(e) => setOrderData({...orderData,name:e.target.value})} />
                                                </div>
                                                <div className="col-6 col-sm-4 form-group">
                                                    <label>{translate.t("Phone")}<div className="required">*</div></label>
                                                    <input type="phone" className="form-control" value={orderData.phone} onChange={(e) => setOrderData({...orderData,phone:e.target.value})} />
                                                </div>
                                                <div className="col-6 col-sm-4 form-group">
                                                    <label>{translate.t("Email")}<div className="required">*</div></label>
                                                    <input type="email" className="form-control" value={orderData.email} onChange={(e) => setOrderData({...orderData,email:e.target.value})} />
                                                </div>
                                                <div className="col-6 col-sm-4 form-group">
                                                    <label>{translate.t("Company")}</label>
                                                    <input type="company" className="form-control" value={orderData.company} onChange={(e) => setOrderData({...orderData,company:e.target.value})} />
                                                </div>
                                                <div className="col-6 col-sm-4 form-group">
                                                    <label>{translate.t("IC")}</label>
                                                    <input type="ic" className="form-control" value={orderData.ic} onChange={(e) => setOrderData({...orderData,ic:e.target.value})} />
                                                </div>
                                                <div className="col-6 col-sm-4 form-group">
                                                    <label>{translate.t("DIC")}</label>
                                                    <input type="dic" className="form-control" value={orderData.dic} onChange={(e) => setOrderData({...orderData,dic:e.target.value})} />
                                                </div> 
                                                <div className="col-12 col-sm-4 form-group">
                                                    <label>{translate.t("RequiredTerm")}<div className="required">*</div></label>
                                                    <div className="datepicker-w100">
                                                        <DatePicker onKeyDown={e => e.preventDefault()} autoComplete="off" className="form-control w-100" dateFormat="yyyy-MM-dd" locale={translate.locale == "cz" ? "cs" : "en"} selected={orderData.deliveryTerm} onChange={(date) => setOrderData({...orderData,deliveryTerm:date})} />
                                                    </div>
                                                </div>         
                                                <div className="col-12 form-group">
                                                    <label>{translate.t("AdditionalInfo")}</label>
                                                    <textarea name="additionalInfo" className="form-control" onChange={(e) => setOrderData({...orderData,additionalInfo:e.target.value})} value={orderData.additionalInfo}></textarea>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 terms">
                                                    <div className="checkbox" onClick={() => setOrderData({...orderData,formConfirmation:!orderData.formConfirmation})}>
                                                        {orderData.formConfirmation &&
                                                            <img src={confirm} />     
                                                        }                                           
                                                    </div>
                                                    <span  onClick={() => setOrderData({...orderData,formConfirmation:!orderData.formConfirmation})}>{translate.t("BySendingTheForm")} <a href=""  onClick={(e) => storeLib.openLink(e,"https://www.favex.cz/wp-content/uploads/2020/10/Z%C3%A1sady-zpracov%C3%A1n%C3%AD-O%C3%9A-FAVEX.pdf",true)}>{translate.t("ProccessPrivacyPolicy")}</a>.</span>
                                                </div>
                                            </div>
                                            <button disabled={sendOrderLoading} className={"send-inquiry " + (sendOrderLoading ? "loading" : "")} onClick={() => storeLib.sendOrderToServer(sendOrder,orderData,currency)}>
                                                {sendOrderLoading ?
                                                    <img src={loadingIcon} className="send-loading" />
                                                    :
                                                    <>                                                    
                                                        {checkAllowBindingOrder() && !!productsData ? translate.t("SendOrder") : translate.t("SendInquiry")}
                                                    </>                                                    
                                                }
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <div className="thank-you">
                                        {orderData.customerID == 0 ? 
                                            <>
                                                {translate.t("ThankYouText1")} #{orderNumber}. {translate.t("ThankYouText2")}
                                            </>
                                            :
                                            <>
                                                {translate.t("ThankYouText3")} #{orderNumber}. {translate.t("ThankYouText2")}
                                            </>
                                        }
                                    </div>
                                }
                            </div>       
                        </div>
                        <div className="help-column ">
                            <div className="help-block"  style={{top:scrollTopOffset + 'px'}}>
                                <div className="title">{translate.t("Help")}</div>
                                <div className="text">
                                    {translate.t("HelpText1")} <strong>{translate.t("Inquire")} </strong> {translate.t("HelpText2")}                    
                                </div>
                            </div>
                        </div>
                        <AccountBlock {...props} />
                    </div>
                </>
                :
                <Loading />
            }
        </div>
    )

}

export default Store;