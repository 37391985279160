/**
 * soubor se seznamem veškerých graphql dotazů pro login
 */
import gql from 'graphql-tag';



export const REGISTER_CUSTOMER = gql`
  mutation loginCustomer($customerRegistrationData: CustomerRegistrationData,$lang: String) {    
    registerCustomer(customerRegistrationData:$customerRegistrationData,lang:$lang){
      loginToken
      customer{
          name
          email
          customerID            
          phone            
          company			
          ic
          dic
          zip   	
          allegroID
          priceListID
      }
    }
  } 
`;



