/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import emailIcon from '../../../Public/Images/email.svg';
import key from '../../../Public/Images/key.svg';
import {LOGIN_USER} from '../Queries/login';
import {USER_CONTEXT} from '../../../GlobalQueries/globalQueries';
//import {Mutation,withApollo} from 'react-apollo';
import { useMutation,useQuery } from '@apollo/react-hooks';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';
import { useApolloClient } from '@apollo/client';
import loadingIcon from '../../../Public/Images/loading.svg';
import { REGISTER_CUSTOMER } from '../Queries/registration';

const Registration = (props) => {

	const client = useApolloClient();
	
	const helper = new Helper();

	const userData = client.readQuery({ query:USER_CONTEXT });
	
    const [registrationData,setRegistrationData] = useState({
        name:"",
        phone:"",
        email:"",
        company:"",
        ic:"",
        dic:"",
		zip:"",
		password:""
    });

	useEffect(() => {
		if(userData && userData.userContext){	
			helper.goToInitialPage(props.history,translate.locale);
		}
	},[]);

	const [registerCustomer, { data,loading: loginLoading, error: loginError }] = useMutation(REGISTER_CUSTOMER,{
		onCompleted(data){
			localStorage.setItem('token', data.registerCustomer.loginToken);
			
			client.writeQuery({
				query:USER_CONTEXT,
				data: {
					userContext: {
						name:data.registerCustomer.customer.name,
						email:data.registerCustomer.customer.email,
						customerID:data.registerCustomer.customer.customerID,           
						phone:data.registerCustomer.customer.phone,            
						company:data.registerCustomer.customer.company,			
						ic:data.registerCustomer.customer.ic,
						dic:data.registerCustomer.customer.dic,
						zip:data.registerCustomer.customer.zip,						
						allegroID:data.registerCustomer.customer.allegroID,
						priceListID:data.registerCustomer.customer.priceListID,
						__typename:"User"
					},
				},
			});
			
			helper.goToInitialPage(props.history,translate.locale);
			
		},						
		onError(err){
			console.log(err);
			
			helper.addNotification("danger",err.message ? err.message : err.toString(),translate.t("Error"));
		}
	});

	
  	const checkRegistration = () => {
	    	  	  
		if(registrationData.name !== '' && registrationData.phone !== '' && registrationData.email !== '' && registrationData.ic !== '' && registrationData.zip !== '' && registrationData.password !== ''){
			if(helper.validateEmail(registrationData.email)){
				registerCustomer({variables:{customerRegistrationData:registrationData,lang:translate.locale}});	
			}else{
				helper.addNotification("danger",translate.t("WrongEmailFormat"),translate.t("Warning"));
			}
		}else{
			helper.addNotification("danger",translate.t("NotFilledRequiredFields"),translate.t("Warning"));
		}

	}

    return (
        <div className="login content">
			<div className="d-flex justify-content-center">
				<div className="col-12 col-lg-6">
					<div className="card login-card">
						<div className="text-center fs30 font-weight-bold my-4">
							{translate.t("Registration")}
						</div>
						<div className="card-body">
							<form >
								<div className="row">
									<div className="col-6 col-sm-4 form-group">
										<label>{translate.t("NameAndSurname")}<div className="required">*</div></label>
										<input type="text" className="form-control" value={registrationData.name} onChange={(e) => setRegistrationData({...registrationData,name:e.target.value})} />
									</div>
									<div className="col-6 col-sm-4 form-group">
										<label>{translate.t("Phone")}<div className="required">*</div></label>
										<input type="text" className="form-control" value={registrationData.phone} onChange={(e) => setRegistrationData({...registrationData,phone:e.target.value})} />
									</div>
									<div className="col-6 col-sm-4 form-group">
										<label>{translate.t("Email")}<div className="required">*</div></label>
										<input type="text" className="form-control" value={registrationData.email} onChange={(e) => setRegistrationData({...registrationData,email:e.target.value})} />
									</div>
									<div className="col-6 col-sm-4 form-group">
										<label>{translate.t("Company")}</label>
										<input type="text" className="form-control" value={registrationData.company} onChange={(e) => setRegistrationData({...registrationData,company:e.target.value})} />
									</div>
									<div className="col-6 col-sm-4 form-group">
										<label>{translate.t("IC")}<div className="required">*</div></label>
										<input type="text" className="form-control" value={registrationData.ic} onChange={(e) => setRegistrationData({...registrationData,ic:e.target.value})} />
									</div>
									<div className="col-6 col-sm-4 form-group">
										<label>{translate.t("DIC")}</label>
										<input type="text" className="form-control" value={registrationData.dic} onChange={(e) => setRegistrationData({...registrationData,dic:e.target.value})} />
									</div>
									<div className="col-6 col-sm-4 form-group">
										<label>{translate.t("Zip")}<div className="required">*</div></label>
										<input type="text" className="form-control" value={registrationData.zip} onChange={(e) => setRegistrationData({...registrationData,zip:e.target.value})} />
									</div> 
									<div className="col-6 col-sm-4 form-group">
										<label>{translate.t("Password")}<div className="required">*</div></label>
										<input type="password" className="form-control" value={registrationData.password} onChange={(e) => setRegistrationData({...registrationData,password:e.target.value})} />
									</div> 
								</div>		        		
								<div className="row mt-4">
									<div className="col-6 pr-3 d-flex align-items-center">
										<button disabled={loginLoading} className={"send-inquiry btn btn-grey w-100 " + (loginLoading ? "loading" : "")} onClick={() => props.history.push("/" + translate.locale + "/store")}>
											{translate.t("Back")} 
										</button>
									</div>
									<div className="col-6 pl-3 d-flex justify-content-end">
										<button type="button" disabled={loginLoading} className={"send-inquiry btn w-100 " + (loginLoading ? "loading" : "")} onClick={() => checkRegistration()}>
											{loginLoading ?
												<img src={loadingIcon} className="send-loading" />
												:
												<>{translate.t("Register")}</>                                                    
											}
										</button>
									</div>
								</div>								
							</form>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
}

export default Registration;
