import gql from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
	{
		notifications @client{
			text
			success
			error
		}
	}
`;


export const USER_CONTEXT = gql`
    {
        userContext{
            name
			phone
            email
			company
			ic
			dic
			zip  
			customerID
			allegroID
			priceListID
        }
    }
`;