import React,{ Component, Fragment, useRef, useState } from 'react';
import search from '../../../Public/Images/search.svg';
import _ from "lodash";

const SearchInput = (props) => {

    const delayedQuery = useRef(_.debounce(q => props.delaydTextFunction(q), 500)).current;


    const setText = (text) => {
        props.setSearchText(text);
        delayedQuery(text);
    }

    return(
        <div className="search-input">
            <input className="form-control" type="text" value={props.searchText} onChange={(e) => setText(e.target.value)} />
            <img src={search} className="search-icon"/>
        </div>
    )

}

export default SearchInput;