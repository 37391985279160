/*
    Úvodní layout administrace
*/
import React,{ Component, useEffect, useState } from 'react';
import Notification from '../GlobalComponents/Notification';
import {Switch, Route } from 'react-router-dom';
import Store from '../Modules/Store/Components/Store';
import Login from '../Modules/Auth/Components/Login';
import Logout from '../Modules/Auth/Components/Logout';

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import ChangePassword from '../Modules/Auth/Components/ChangePassword';
import Registration from '../Modules/Auth/Components/Registration';
import ResetPassword from '../Modules/Auth/Components/ResetPassword';

//import Homepage from '../Modules/Content/Components/Homepage';


const Layout = () => {
	  	
	const [scrollTopOffset,setScrollTopOffset] = useState(0);
	
    useEffect(() => {
        function setScrollOffset(e){
            if(e.data[0] == 'setScrollOffset') { 
                //iframe.style.setProperty('height',e.data[1]+"px");
				const offset = e.data[1];
				if(offset <= 0){
					setScrollTopOffset(Math.abs(offset));
				}
            } 
            
        }
        window.addEventListener("message", setScrollOffset);

        return () => {
            // remove resize listener
            window.removeEventListener('message', setScrollOffset);
        }
	},[])
	
    return (
		<div className="wholeContent">			       
		
			<div id="layout">	
				<Switch>        
					<Route path='/:l/store' component={Store}></Route>
					<Route path='/:l/login' component={Login}></Route>
					<Route path='/:l/logout' component={Logout}></Route>
					<Route path='/:l/account' component={ChangePassword}></Route>
					<Route path='/:l/registration' component={Registration}></Route>
					<Route path='/:l/reset-password' component={ResetPassword}></Route>
					
				</Switch>
				<div className="notification-wrapper" style={{top:scrollTopOffset + 'px'}}>
					<ReactNotification/>
				</div>
			</div>
					
		</div>
    );
}

export default Layout;
